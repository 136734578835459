import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../data-models/user';
import { isPlatformBrowser } from '@angular/common';
import { Login } from '../data-models/login';
import { IdentityPlatformType } from '../../shared/enum/identity-platform-type';
import { forgetPassword } from '../data-models/forget-password';
import { resetPassword } from '../data-models/reset-password';
import { changePassword } from '../data-models/change-password';
import { UserProfile } from '../data-models/userProfile';
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private baseUrl: string | undefined;
  private currentUserSource=new BehaviorSubject<User|null>(null);
  currentUser$=this.currentUserSource.asObservable();

  private emailSource = new BehaviorSubject<string|null>(null);
  resetEmail = this.emailSource.asObservable();

  constructor(private http: HttpClient,@Inject(PLATFORM_ID) private platformId: object) {
    this.baseUrl = environment.apiUrl;
   }

   login(model:Login){
    return this.http.post<User>(this.baseUrl+"api/WebsiteAccount/login-student",model).pipe(
     map((respose:any)=>{
       const user=respose.data;
       if(user != null){
         this.setCurrentUser(user);
       }
     })
    );
   }


   forgetPassword(model:forgetPassword){
    return this.http.post<User>(this.baseUrl+"api/WebsiteAccount/foget-password",model);
   }

   resetPassword(model:resetPassword){
    return this.http.post<User>(this.baseUrl+"api/Account/reset-password",model);
   }
   changePassword(model:changePassword){
    return this.http.put<User>(this.baseUrl+"api/WebsiteAccount/update-password",model);
   }
   
   googleLogin(token:any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<User>(this.baseUrl+"api/WebsiteAccount/googleLogin-student",JSON.stringify(token), { headers }
  ).pipe(
     map((respose:any)=>{
       const user=respose.data;
       if(user != null){
         this.setCurrentUser(user);
       }
     })
    );
   }


   setCurrentUser(user:User){
    const roles=this.getDecodeToken(user.token).role;
    user.role=roles;
    //Encrypt
    localStorage.setItem("student",JSON.stringify(user));
    this.currentUserSource.next(user);
  }
  setPartOfCurrentUser(user:User){
    const currentUser = JSON.parse(localStorage.getItem("student") || '{}');
    currentUser.phoneNumber = user.phoneNumber;
    currentUser.fullName = user.fullName;
    localStorage.setItem("student", JSON.stringify(currentUser));
    this.currentUserSource.next(currentUser);
  }

  getDecodeToken(token:string){
    return JSON.parse(atob(token.split('.')[1]));
  }

  logout(){
    localStorage.removeItem("student");
    this.currentUserSource.next(null);
  }

  setResetEmail(email: string): void {
    this.emailSource.next(email);
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      // Check if localStorage is available
      return !!localStorage.getItem('student');
    }
    return false; // Default to false for SSR or non-browser environments
  
  }
  createAccount(model:Login){
    return this.http.post<User>(this.baseUrl+"api/WebsiteAccount/create-account",model).pipe(
      map((respose:any)=>{
        const user=respose;
        if(user != null){
          this.setCurrentUser(user);
        }
      })
     );
   }
  getMyProfile() : Observable<User>{
    return this.http.get<User>(this.baseUrl + "api/WebsiteAccount/get-my-profile");
  }
  updateProfile(formData: FormData) {
    return this.http.put<User>(this.baseUrl + "api/WebsiteAccount/update-user-Profile", formData).pipe(
      map((response: any) => {
        const newUserData = response.data;
        if (newUserData) {
          this.updateUser(newUserData);
        }
      })
    );
  }
  updateUser(newUserData: User) {
    const currentUser = JSON.parse(localStorage.getItem("student") || '{}');
    const updatedUser = {
      ...currentUser,
      id: newUserData.id,
      studentID: newUserData.studentID,
      fullName: newUserData.fullName,
      userName: newUserData.userName,
      email: newUserData.email,
      imagePathUrl: newUserData.imagePathUrl,
      phoneNumber: newUserData.phoneNumber,
      hasSubscription: newUserData.hasSubscription,
      studentStatus: newUserData.studentStatus,
      aboutMe: newUserData.aboutMe,
      stageId: newUserData.stageId,
      stageName: newUserData.stageName,
      academicYearId: newUserData.academicYearId,
      academicYearName: newUserData.academicYearName,
      yearID : newUserData.academicYearId,
      yearName: newUserData.academicYearName,
      isGoogleUser: newUserData.isGoogleUser
    };

    localStorage.setItem("student", JSON.stringify(updatedUser));
    this.currentUserSource.next(updatedUser);
  }
}
